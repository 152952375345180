<template>
  <div class="" style="height:calc(100vh - 110px)">
    <v-card height="100%" class="d-flex">
        <v-container v-if="$vuetify.breakpoint.name == 'xl'" class="item_style" :style="{'width':mini?'256px':'30px'}">
            <v-navigation-drawer disable-resize-watcher hide-overlay permanent :style="{'display':mini?'block':'none'}">
                <template v-slot:prepend>
                    <v-container>
                        <v-list-item two-line>
                            <v-list-item-content>
                                <v-list-item-title>教程</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-container>
                </template>
                <v-divider></v-divider>
                <v-list nav dense>
                    <v-list-item-group v-model="activeId" color="indigo">
                        <v-list-item v-for="item in items" :key="item.title">
                            <v-list-item-content @click="gotoIfream(item)">
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-navigation-drawer>
        <v-container>
            <v-img @mouseover="shouqiOver('shouqi')" @mouseleave="shouqileave('shouqi')" v-if="mini" @click.stop="mini = !mini" width="44" height="44" class="icon_style" :src="shouqi"></v-img>
            <v-img @mouseover="shouqiOver('zhankai')" @mouseleave="shouqileave('zhankai')" v-else  @click.stop="mini = !mini" width="44" height="44" class="icon_style" :src="zhankai"></v-img>
        </v-container>
        </v-container>
        <v-container v-if="src">
            <iframe :src="src" frameborder="0" style="width:100%;height:100%"></iframe>
        </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'MetaverseExperienceDetail',
  data () {
      return {
        items: [
          { title: '新手教学', id:'xinhsoujiaoxu',src:'https://h5.ophyer.cn/metaverse/resource/metaverseHtml/xinhsoujiaoxu.html'},
          { title: '创作开始-制作第一个领地',id:'chuangzuokaishi', icon: 'mdi-account',src:'https://h5.ophyer.cn/metaverse/resource/metaverseHtml/chuangzuokaishi.html'},
        //   { title: '素材商店简介', id:'sucaijianjie',icon: 'mdi-account-group-outline',src:'https://h5.ophyer.cn/metaverse/resource/metaverseHtml/sucaijianjie.html'},
          { title: '如何创建VR全景',id:'chuangjianVR', icon: 'mdi-account-group-outline',src:'https://h5.ophyer.cn/metaverse/resource/metaverseHtml/chuangjianVR.html'},
          { title: 'VR编辑器功能介绍',id:'VRgongneng', icon: 'mdi-account-group-outline',src:'https://h5.ophyer.cn/metaverse/resource/metaverseHtml/VRgongneng.html'},
          { title: 'VR编辑器辅助功能介绍',id:'VRfuzhu', icon: 'mdi-account-group-outline',src:'https://h5.ophyer.cn/metaverse/resource/metaverseHtml/VRfuzhu.html'},
          { title: '3D编辑器界面说明', id:'3Djiemian',icon: 'mdi-account-group-outline',src:'https://h5.ophyer.cn/metaverse/resource/metaverseHtml/3Djiemian.html'},
          { title: '3D编辑器功能介绍', id:'3Dgongeng',icon: 'mdi-account-group-outline',src:'https://h5.ophyer.cn/metaverse/resource/metaverseHtml/3Dgongeng.html'},
          { title: '如何在全景中添加商品热点',id:'shangpinredian', icon: 'mdi-account-group-outline',src:'https://h5.ophyer.cn/metaverse/resource/metaverseHtml/shangpinredian.html'},
          { title: '如何使用沙盘功能',id:'shapangongneng', icon: 'mdi-account-group-outline',src:'https://h5.ophyer.cn/metaverse/resource/metaverseHtml/shapangongneng.html'},
          { title: '如何使用3D编辑器编写脚本',id:'3Djiaoben', icon: 'mdi-account-group-outline',src:'https://h5.ophyer.cn/metaverse/resource/metaverseHtml/3Djiaoben.html'},
        ],
        activeId:1,
        src:'',
        mini: true,
        shouqi:require('../../../../public/img/icons/metaverse/shouhui.png'),
        zhankai:require('../../../../public/img/icons/metaverse/zhankai.png')
      }
    },
  created() {
      this.items.map((item,index) => {
          if(this.$route.query.id === item.id){
              this.src = item.src,
              this.activeId = index
          }
      })
  },
  methods: {
    gotoIfream(item){
        this.src = item.src
    },
    shouqiOver(type){
        switch(type){
            case 'shouqi':
                this.shouqi = require('../../../../public/img/icons/metaverse/shouhui_1.png')
                break;
            case 'zhankai':
                this.zhankai = require('../../../../public/img/icons/metaverse/zhankai_1.png')
                break;
        }
    },
    shouqileave(type){
        switch(type){
            case 'shouqi':
                this.shouqi = require('../../../../public/img/icons/metaverse/shouhui.png')
                break;
            case 'zhankai':
                this.zhankai = require('../../../../public/img/icons/metaverse/zhankai.png')
                break;
        }
    }
  }

}
</script>

<style lang="scss" scoped>
.item_style{
    cursor: pointer;
    position: relative;
}
.icon_style{
    position:absolute;
    top: 20px;
    right:-21px;
}

</style>